<template>
    <div class="q-pa-md top-level row q-gutter-md justify-evenly items-stretch">

        <q-card class="my-card" v-for="(doc,idx) in steadyPics" :key="'doc_'+idx">
            <q-card-section class="card-section-picture">
                <q-img :src="doc.picture" />
            </q-card-section>
            <q-card-section class="card-section-description">
                <div class="text-h6" v-html="doc.title"></div>
                <div class="text-subtitle2" v-html="doc.description" />
            </q-card-section>
            <q-card-actions>
                <div class="col-12" style="text-align:right;"><a :href="doc.url" target="_blank" class="openUrl">OPEN DEMO IN NEW TAB</a></div>
            </q-card-actions>
        </q-card>

    </div>
</template>

<script>
export default ({
    name: 'Webdesigns',
    data() {
        return {
            steadyPics: [
                {
                    picture: '/static/webdesigns/cropvitality.png',
                    title: 'cropvitality.com',
                    description: 'Web site conception and implementation. Some dynamic parts are implemented as AngularJS apps.',
                    url: 'https://www.cropvitality.com/',
                },
                {
                    picture: '/static/webdesigns/pbleiner.png',
                    title: 'pbleiner.com',
                    description: 'Web site conception and implementation. Some dynamic parts are implemented dynamically using AngularJS apps.',
                    url: 'https://www.pbleiner.com/en',
                },
                {
                    picture: '/static/webdesigns/eda_website.png',
                    title: 'European Defense Agency',
                    description: 'Web site design and implementation. This single page app (SPA) is enterely written in Vue.js. The access to the effective web site is restricted but a copy can be tested by clicking on link hereunder.',
                    url: '/static/frontend/tim_related/tim_eda/index.html',
                },
            ]
        };
    },
})
</script>

<style scoped lang="less">
.top-level {
    padding: 6px;
}
.my-card {
    max-width: 500px;
    color: #666;
    display: flex;
    flex-direction: column;
    .card-section-picture {
        flex:1 1 auto;
        text-align: center;
        overflow:hidden;
        padding: 0;
    }
    .card-section-description {
        flex: 0 0 4em;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
        .text-h6 {
            font-weight: bold;
        }
    }
    .q-card__actions {
        flex:0 0;
        .openUrl {
            text-decoration: none;
            color: #777;
            font-weight: bolder;
        }
    }
}
</style>
